import { Console } from 'console';
import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
// import { HttpService } from "../../services/http.report";
import { HttpService } from "../../services/http.services";


// const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/services`;
const RESOURCE_ENDPOINT = 'research/research-admin-dashboard/summary/service-delivery-reports';
// const RESOURCE_ENDPOINT = 'accommodation/report/service-delivery-report';

const endpoints = {
  list: () => `${RESOURCE_ENDPOINT}`,
  getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
  create: () => `${RESOURCE_ENDPOINT}`,
  getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
  update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
  dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
  serviceDeliveryReport: () => `${RESOURCE_ENDPOINT}`,
};

export default class ReportResearchApi {


  public serviceDeliveryReport = (
    payload = {},
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.serviceDeliveryReport();
    return HttpService.post(url, payload, params, headers);
  };
}
 
