
import { read, utils, writeFileXLSX } from 'xlsx';
import * as XLSX from 'xlsx';

export default class ExportUtils {
    public export2Word =(element, filename = '') =>{
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title><style>.pagebreak {page-break-before:always}</style></head><body>";
        var postHtml = "</body></html>";
        var html = preHtml+window.document.getElementById(element)?.innerHTML+postHtml;

        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });

        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

        // Specify file name
        filename = filename?filename+'.doc':'document.doc';

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if((window.navigator as any).msSaveOrOpenBlob ){
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    }
    public export2Xls =(tableID, filename = '') =>{
        var downloadLink;
        var dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect?.outerHTML.replace(/ /g, '%20');

        // Specify file name
        filename = filename?filename+'.xlsx':'excel_data.xlsx';

        // Create download link element
        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if((window.navigator as any).msSaveOrOpenBlob && tableHTML){
            var blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }
    }
    public export2XLSX =(tableID, filename = 'idsdp_most') =>{
        const data = document.getElementById(tableID);
        const wb = utils.table_to_book(data, {raw:true});
        writeFileXLSX(wb, filename + ".xlsx");
    }

    public export2CSV =(tableID, filename = 'idsdp_most') =>{
        // Get the HTML table element
        const table = document.getElementById(tableID);

        // Convert HTML table to worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Convert worksheet to CSV format
        const csv = XLSX.utils.sheet_to_csv(ws);

        // Create a Blob with the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        // Create a download link and trigger a click event to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename+'.csv';
        link.click();
    }
}
