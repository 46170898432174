import _ObjectUtils from "./ObjectUtils";
import _ArrayUtils from "./ArrayUtils";
import _JsonUtils from "./JsonUtils";
import _StringUtils from "./StringUtils";
import _MathUtils from "./MathUtils";
import _NumberUtils from "./NumberUtils";
import _DateTimeUtils from "./DateTimeUtils";
import _UrlUtils from "./UrlUtils";
import { Message as _Message} from "./AlertUtils";
import { Notification as _Notification } from "./AlertUtils";
import { AntModal as _AntModal } from "./AlertUtils";
import _CommonUtils from "./CommonUtils";
import _EnumUtils from "./EnumUtils";
import _TreeUtils from "./TreeUtils";
import _ErrorUtils from "./ErrorUtils";
import _ExportUtils from "./ExportUtils";

export const ObjectUtils    = new _ObjectUtils();
export const ArrayUtils     = new _ArrayUtils();
export const JsonUtils      = new _JsonUtils();
export const StringUtils    = new _StringUtils();
export const MathUtils      = new _MathUtils();
export const NumberUtils    = new _NumberUtils();
export const DateTimeUtils  = new _DateTimeUtils();
export const UrlUtils       = new _UrlUtils();
export const Message        = new _Message();
export const Notification   = new _Notification();
export const AntModal       = new _AntModal();
export const CommonUtils    = new _CommonUtils();
export const EnumUtils      = new _EnumUtils();
export const TreeUtils      = new _TreeUtils();
export const ErrorUtils     = new _ErrorUtils();
export const ExportUtils    = new _ExportUtils();